<template>
  <app-select
    label="Meio de pagamento"
    v-bind="$attrs"
    :dense="dense"
    v-on="$listeners"
    :items="paymentMethods"
    :item-text="row => row.label"
    :item-value="row => row.value"
  />
</template>

<script>

import paymentMethods from "../../../enums/paymentMethods";

export default {
  props: {
    dense: {
      default: true,
    },
  },

  data : () => ({
    paymentMethods: paymentMethods,
  }),

};
</script>

<style></style>
