<template>
  <app-select
    v-bind="$attrs"
    v-on="$listeners"
    label="Operação"
    :items="operations"
    :item-text="(item) => item.name"
    :item-value="(item) => item.id"
  />
</template>

<script>
export default {
  props: {},

  data: () => ({
    operations: [
      {
        id: null,
        name: "Todos",
      },
      {
        id: 1,
        name: "Venda",
      },
      {
        id: 2,
        name: "Devolução",
      },
    ],
  }),
};
</script>

<style></style>
